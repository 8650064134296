import React, { useEffect, useState } from "react";
import {
  Text,
  Box,
  Button,
  TextInput,
  Table,
  Loader,
  ActionIcon,
  Card,
  Divider,
  Stack,
  Select,
  createStyles,
} from "@mantine/core";
import { Layout } from "../components/Layout";
import axios from "axios";
import { notifications } from "@mantine/notifications";
import { IconX } from "@tabler/icons-react";

interface User {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
}

const useStyles = createStyles((theme) => ({
  container: {
    maxWidth: "100%",
    width: "100%",
    padding: theme.spacing.md,
    background: "white",
    [theme.fn.largerThan("sm")]: {
      maxWidth: "830px",
      width: "830px",
    },
  },
  tableContainer: {
    overflowX: "auto",
  },
  responsiveTable: {
    minWidth: "100%",
    [theme.fn.smallerThan("sm")]: {
      "& th, & td": {
        padding: "8px 4px",
      },
    },
  },
  responsiveButton: {
    [theme.fn.smallerThan("xs")]: {
      padding: "4px 8px",
      fontSize: theme.fontSizes.xs,
    },
  },
}));

export const CheckInPage = () => {
  const { classes } = useStyles();
  const [terminalOptions, setTerminalOptions] = useState<
    {
      id: string;
      name: string;
    }[]
  >([]);
  const [selectedTerminal, setSelectedTerminal] = useState("");
  const [users, setUsers] = useState<User[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [newUser, setNewUser] = useState({
    email: "",
    firstName: "",
    lastName: "",
  });
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [checkInResult, setCheckInResult] = useState(null);

  const searchUsers = async (query: string) => {
    setIsSearchLoading(true);
    setNoResults(false);
    try {
      const response = await axios.post(
        (process.env.REACT_APP_API_HOST || "") + "/user/search",
        {
          search: query,
        }
      );
      setUsers(response.data);
      setNoResults(response.data.length === 0);
    } catch (error) {
      console.error("Error searching users:", error);
      setNoResults(true);
    } finally {
      setIsSearchLoading(false);
    }
  };

  useEffect(() => {
    if (searchValue.length >= 3) {
      const delayDebounceFn = setTimeout(() => {
        searchUsers(searchValue);
      }, 300);

      return () => clearTimeout(delayDebounceFn);
    } else {
      setUsers([]);
    }
  }, [searchValue]);

  const getTerminalOptions = async () => {
    const response = await axios.post(
      (process.env.REACT_APP_API_HOST || "") +
        "/terminal/getOnboardingTerminals",
      {
        pin: localStorage.getItem("normal-pin"),
      }
    );

    return response?.data;
  };

  useEffect(() => {
    getTerminalOptions().then((response) => {
      if (response && Array.isArray(response)) {
        setTerminalOptions(response);
      }

      const urlParams = new URLSearchParams(window.location.search);
      const terminalId = urlParams.get("terminalId") || "";
      if (terminalId) {
        setSelectedTerminal(terminalId);
      }
    });
  }, []);

  const handleUserSelect = (user: User) => {
    setSelectedUser(user);
    setCheckInResult(null);
  };

  const handleCheckIn = () => {
    if (selectedTerminal && selectedUser) {
      axios
        .post((process.env.REACT_APP_API_HOST || "") + "/user/checkInEvent", {
          terminalId: selectedTerminal,
          userId: selectedUser.id,
        })
        .then((response) => {
          setCheckInResult(response.data);
          notifications.show({
            title: "Check-In Successful",
            message: `Card Alias ${response.data.cardAlias} has been successfully linked to ${selectedUser.email}`,
            color: "green",
          });
          setSelectedUser(null);
        })
        .catch((error) => {
          const errorMessage =
            error.response?.data?.message || "Error during check-in";
          notifications.show({
            title: "Error",
            message: errorMessage,
            color: "red",
          });
        });
    }
  };

  const handleAddUser = async () => {
    try {
      const response = await axios.post(
        (process.env.REACT_APP_API_HOST || "") +
          "/user/createThroughCheckInPage",
        newUser
      );
      const createdUser = response.data;
      setUsers([...users, createdUser]);
      setSelectedUser(createdUser);
      setNewUser({ email: "", firstName: "", lastName: "" });

      notifications.show({
        title: "Success",
        message: "User created successfully",
        color: "green",
      });
    } catch (error) {
      const errorMessage =
        (error as any)?.response?.data?.message || "Error creating user";
      notifications.show({
        title: "Error",
        message: errorMessage,
        color: "red",
      });
    }
  };

  return (
    <Box className={classes.container}>
      <Select
        label="Select a Check-In Terminal"
        placeholder="Select a terminal"
        data={terminalOptions.map((terminalOption) => ({
          value: terminalOption.id,
          label: terminalOption.name,
        }))}
        value={selectedTerminal}
        onChange={(value) => {
          if (value) {
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.set("terminalId", value);
            window.history.replaceState(
              null,
              "",
              `${window.location.pathname}?${urlParams.toString()}`
            );
            setSelectedTerminal(value);
          }
        }}
        mb="md"
      />

      <TextInput
        label="Search Email or Name for check-in"
        placeholder="Type at least 3 characters"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        mb="md"
        rightSection={
          <ActionIcon onClick={() => setSearchValue("")}>
            <IconX size={16} />
          </ActionIcon>
        }
      />

      <Box
        my="xl"
        style={{
          minHeight: "180px",
          maxHeight: "180px",
          overflowY: "auto",
        }}
        className={classes.tableContainer}
      >
        {isSearchLoading ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "180px",
            }}
          >
            <Loader />
          </Box>
        ) : (
          <Table className={classes.responsiveTable}>
            <thead>
              <tr>
                <th>Emailth</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Actionth</th>
              </tr>
            </thead>
            <tbody>
              {users.length > 0 ? (
                users.map((user) => (
                  <tr key={user.id}>
                    <td>{user.email}</td>
                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>
                      <Button
                        size="xs"
                        onClick={() => handleUserSelect(user)}
                        color="green"
                        className={classes.responsiveButton}
                      >
                        Select
                      </Button>
                    </td>
                  </tr>
                ))
              ) : searchValue.length >= 3 && noResults ? (
                <tr>
                  <td colSpan={4} style={{ textAlign: "center" }}>
                    No results found
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan={4}>&nbsp;</td>
                </tr>
              )}
              <tr>
                <td>
                  <TextInput
                    placeholder="Email"
                    value={newUser.email}
                    onChange={(e) =>
                      setNewUser({ ...newUser, email: e.target.value })
                    }
                  />
                </td>
                <td>
                  <TextInput
                    placeholder="First Name"
                    value={newUser.firstName}
                    onChange={(e) =>
                      setNewUser({ ...newUser, firstName: e.target.value })
                    }
                  />
                </td>
                <td>
                  <TextInput
                    placeholder="Last Name"
                    value={newUser.lastName}
                    onChange={(e) =>
                      setNewUser({ ...newUser, lastName: e.target.value })
                    }
                  />
                </td>
                <td>
                  <Button
                    size="xs"
                    onClick={handleAddUser}
                    color="green"
                    className={classes.responsiveButton}
                  >
                    Add New
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        )}
      </Box>

      {selectedUser && selectedTerminal && (
        <Card mt="xl" shadow="sm" padding="lg">
          <Text weight={700} size="lg" mb="md">
            Selected User
          </Text>
          <Divider mb="md" />
          <Stack spacing="sm">
            <Text>
              <strong>Name:</strong>{" "}
              {`${selectedUser.firstName} ${selectedUser.lastName}`}
            </Text>
            <Text>
              <strong>Email:</strong> {selectedUser.email}
            </Text>
          </Stack>
          <Button
            mt="md"
            onClick={handleCheckIn}
            className={classes.responsiveButton}
          >
            Check In
          </Button>
        </Card>
      )}
    </Box>
  );
};
